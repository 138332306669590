<template>
  <div class="three">
    <div class="three-container">
      <div class="shop-input">
        <span style="line-height: 43px; margin-right: 10px">直播总时长</span>
        <el-input-number :disabled="true" :controls="false" style="width: 80px" v-model="minute"></el-input-number>
        <span style="line-height: 43px; margin-left: 10px">分</span>
        <el-input-number :disabled="true" :controls="false" style="width: 80px; margin-left: 10px" v-model="second">
        </el-input-number>
        <span style="line-height: 43px; margin-left: 10px">秒</span>
      </div>
      <el-form ref="rulesForm" :model="rulesForm" :rules="rules">
        <div class="shop-input">
          <div style="margin: 20px 0 20px 137px">点击率</div>
          <el-form-item label="直播间基本信息" prop="studio_info_click">
            <el-input-number :controls="false" style="width: 120px" :min="0.01" :max="10" placeholder="0.01%-10%"
              v-model="rulesForm.studio_info_click" @input="inputInspect"></el-input-number>
            <span style="margin-left: 5px">%</span>
          </el-form-item>
          <el-form-item label="直播间配置" prop="studio_setting_click" style="margin-left: 28px">
            <el-input-number :controls="false" style="width: 120px" v-model="rulesForm.studio_setting_click" :min="0.01"
              :max="10"></el-input-number>
            <span style="margin-left: 5px">%</span>
          </el-form-item>
        </div>

        <div class="upload" style="margin-top: 30px">
          <el-form-item label="贴片素材" prop="fileList">
            <span style="color: #ababbb">（支持扩展名：rar 和 zip，文件不超过10M）</span>
            <el-upload ref="upload" style="margin-top: 10px" class="upload-demo" action="script/upload"
              :headers="headersParams" accept=".rar, .zip" :show-file-list="true" :on-remove="handleRemove"
              :before-upload="beforeFileUpload" :on-success="handleCoverSuccess" :file-list="rulesForm.fileList"
              name="resources">
              <el-button size="small" type="primary">选择文件</el-button>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="getBack">
      <el-button class="getBackBtn" type="primary" @click="backTo">上一步</el-button>
      <el-button class="getBackBtn" type="primary" @click="nextTo('rulesForm')">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { min } from "moment";
import { createScriptData } from "@/utils/apis";
export default {
  name: "newScriptManagementThree",
  components: {},
  data() {
    return {
      editData: "",
      oneData: {},
      twoData: "",
      threeData: [],
      liveRes: "",
      timer: "",
      headersParams: {
        Authorization: localStorage.getItem("adminToken") || "",
      },
      rules: {
        studio_info_click: [
          { required: true, message: "请输入点击率", trigger: "blur" },
        ],
        studio_setting_click: [
          { required: true, message: "请输入点击率", trigger: "blur" },
        ],
        fileList: [
          {
            required: true,
            message: "请上传文件",
            trigger: "blur",
          },
        ],
      },
      minute: void 0, //分
      second: void 0, //秒
      rulesForm: {
        studio_info_click: "", //基本信息
        studio_setting_click: "", //配置
        fileList: [], //上传文件列表
      },
    };
  },

  mounted() {
    this.oneData = JSON.parse(localStorage.getItem("oneForm"));
    this.twoData = JSON.parse(localStorage.getItem("twoForm"));
    this.threeData = JSON.parse(localStorage.getItem("threeForm"));
    if (!this.$route.query.id) {
      // this.liveRes = JSON.parse(localStorage.getItem("liveTotal"));
      // this.minute = this.liveRes.minute;
      // if (this.liveRes.second == -1) {
      //   this.second = 0;
      // } else {
      //   this.second = this.liveRes.second;
      // }
      this.getLiveTime()
    } else {
      // window.setInterval(() => {
      //   this.liveRes = JSON.parse(localStorage.getItem("liveTotal"));
      //   this.minute = this.liveRes.minute;
      //   if (this.liveRes.second == -1) {
      //     this.second = 0;
      //   } else {
      //     this.second = this.liveRes.second;
      //   }
      // }, 10);
      this.getLiveTime()
      this.editData = JSON.parse(localStorage.getItem("editData"));
      this.editScriptData();
    }
  },

  methods: {
    getLiveTime() {
      this.timer = window.setInterval(() => {
        this.liveRes = JSON.parse(localStorage.getItem("liveTotal"));
        this.minute = this.liveRes.minute ? this.liveRes.minute : 0;
        if (this.liveRes.second == -1) {
          this.second = 0;
        } else {
          this.second = this.liveRes.second;
        }
      }, 100);
    },
    inputInspect() {
      if (this.rulesForm.studio_info_click == 0) {
        this.$message({
          type: "warming",
          message: "设置的点击率必须大于0！",
        });
      }
    },
    // 上传文件成功
    handleCoverSuccess(res) {
      if (res.code === 200) {
        this.fileName = res.data.name;
        this.fileSrc = res.data.src;
        let obj = {
          name: res.data.name,
          url: res.data.src,
        };
        // 如果重新上传，将前一个覆盖
        this.rulesForm.fileList.shift();
        this.rulesForm.fileList.push(obj);
      }
    },
    // 删除文件前
    // beforeRemoveAttachment(file) {
    //   return this.$confirm(`确定移除 ${file.name}？`);
    // },
    // 删除文件
    handleRemove(file) {
      let index = this.rulesForm.fileList.findIndex((fileItem) => {
        return fileItem.uid === file.uid;
      });
      this.rulesForm.fileList.splice(index, 1);
    },
    // 上传文件大小格式限制
    beforeFileUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isZIP = testmsg === "zip";
      const isRAR = testmsg === "rar";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isZIP && !isRAR) {
        this.$message({
          message: "上传文件只能是 zip、rar格式!",
          type: "warning",
        });
      }
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return (isRAR || isZIP) && isLt10M;
    },
    // 上一步
    backTo() {
      if (this.$route.query.id) {
        this.$router.push({
          path: "/scriptManagement/newScriptManagement/three",
          query: { id: this.$route.query.id },
        });
      } else {
        this.$router.push({
          path: "/scriptManagement/newScriptManagement/three",
        });
      }
    },
    // 创建脚本
    nextTo(rulesForm) {
      let params = {
        script_name: this.oneData.script_name,
        category_id: this.oneData.category_id,
        flow_value: this.oneData.flow_value,
        studio_src: "",
        studio_info_click: "",
        studio_setting_click: "",
        total_time: Number(this.minute * 60 + this.second),
        goods_number: "",
        start_time: 0,
        end_time: 0,
        script_content: [],
        goods_content: this.twoData,
        bag_content: [],
        studio_name: "",
      };
      if (this.$route.query.id) {
        params.id = this.$route.query.id;
      }
      this.threeData.forEach((item, index) => {
        switch (item.type) {
          case 1:
            item.list.forEach((subitem) => {
              params.script_content.push({
                minute: subitem.minute,
                seconds: subitem.seconds,
                script_content: subitem.script_content,
                key_word: subitem.key_word,
                miss_key_word: subitem.miss_key_word,
                turnover_rate: subitem.turnover_rate,
                barrage: subitem.barrage,
                start_time: subitem.start_time,
                level: subitem.level,
              });
            });
            break;
          case 2:
            if (item.list.goodsNum) {
              params.goods_number = item.list.goodsNum;
            }
            if (item.list.start_time) {
              params.start_time = item.list.start_time;
            }
            if (item.list.end_time) {
              params.end_time = item.list.end_time;
            }
            break;
          case 3:
            item.list.forEach((subitem) => {
              params.bag_content.push({
                bag_id: subitem.bag_id,
                start_time: subitem.start_time,
                end_time: subitem.end_time,
              });
            });
            break;
        }
      });
      this.$refs[rulesForm].validate((valid) => {
        if (valid) {
          if (this.rulesForm.studio_setting_click == 0) {
            this.$message({
              type: "error",
              message: "请设置直播间配置大于0",
            });
          } else {
            if (this.rulesForm.studio_info_click) {
              params.studio_info_click = this.rulesForm.studio_info_click;
            }
            if (this.rulesForm.studio_setting_click) {
              params.studio_setting_click = this.rulesForm.studio_setting_click;
            }
            if (this.rulesForm.fileList) {
              this.rulesForm.fileList.forEach((item) => {
                params.studio_src = item.url;
                params.studio_name = item.name;
              });
            }
            createScriptData(params).then((res) => {
              if (res.code === 200) {
                localStorage.removeItem("oneForm");
                localStorage.removeItem("twoForm");
                localStorage.removeItem("threeForm");
                localStorage.removeItem("liveTotal");
                if (this.$route.query.id) {
                  localStorage.removeItem("editData");
                  this.$router.push({
                    path: "/scriptManagement/newScriptManagement/success",
                    query: { id: this.$route.query.id },
                  });
                  clearInterval(this.timer);
                } else {
                  this.$router.push({
                    path: "/scriptManagement/newScriptManagement/success",
                  });
                  clearInterval(this.timer);
                }
              }
            });
          }
        }
      });
    },
    // 编辑
    editScriptData() {
      this.rulesForm.studio_info_click = this.editData.studio_info_click;
      this.rulesForm.studio_setting_click = this.editData.studio_setting_click;
      let obj = {
        name: this.editData.studio_name,
        url: this.editData.studio_src,
      };
      this.rulesForm.fileList.push(obj);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="scss">
.three {
  padding: 40px 0 70px 120px;

  .header-tag {
    display: flex;
    align-items: center;

    .header-tag-box {
      width: 6px;
      height: 12px;
      background-color: #2821fc;
      border-radius: 3px;
    }
  }

  .three-container {
    margin-top: 30px;
  }

  .getBack {
    display: flex;
    justify-content: center;
    margin-top: 160px;

    .getBackBtn {
      width: 100px;
      height: 40px;
      line-height: 0;
      border-radius: 4px;
    }
  }

  .upload-demo {
    width: 360px;
  }
}
</style>
